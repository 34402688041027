<template>
  <div>
    <videos-list lang="EN"></videos-list>
  </div>
</template>

<script>
import VideosList from './VideosList.vue'

export default {
  components: {
    VideosList,
  },
  data() {
    return {}
  },
}
</script>
