<template>
  <span>
    <b-badge
        v-if="rating"
        :variant="variant"
        class="badge-glow"
    > {{ rating }}</b-badge>
  </span>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  data() {
    return {}
  },
  computed: {
    variant() {
      if (this.rating <= 4) {
        return 'danger'
      }
      if (this.rating <= 7) {
        return 'warning'
      }
      return 'success'
    },
  },
  props: ['rating'],
}
</script>
