<template>
  <b-card
      :img-src="cover"
      :img-alt="video.title"
      img-top
      no-body
      ref="video_box"
  >
    <div class="video-cover-badge" v-show="video.rating">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <rating-badge :rating="video.rating"></rating-badge>
        </div>
      </div>
    </div>

    <div class="video-cover-votes-badge" v-show="video.votes_count">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <span>
            <b-badge
                :variant="(video.votes_count > 2) ? 'success' : 'warning'"
                class="badge-glow"
            >
              <feather-icon icon="StarIcon"/>
              {{ video.votes_count }}
            </b-badge>
          </span>
        </div>
      </div>
    </div>

    <!-- body -->
    <b-card-body>
      <b-card-title>
        {{ video.title }}
      </b-card-title>
      <b-row>
        <b-col
            md="6"
            sm="6"
            class="mb-2 mb-md-0 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-link :href="video.url" target="_blank">
                <b-avatar
                    size="48"
                    variant="light-primary"
                >
                  <feather-icon
                      size="24"
                      icon="YoutubeIcon"
                  />
                </b-avatar>
              </b-link>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <b-link :href="video.url" target="_blank">
                  Youtube
                </b-link>
              </h4>
              <b-card-text class="font-small-3 mb-0">

              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
            md="6"
            sm="6"
            class="mb-2 mb-md-0 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                  size="48"
                  variant="light-success"
              >
                <feather-icon
                    size="24"
                    icon="CalendarIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ video.release_date | formatDate}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Релиз
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>


    <!-- card links -->
    <b-card-body>
      <app-collapse>
        <app-collapse-item title="Клип">
          <youtube :video-id="video.youtube_id" :player-width="width" player-height="250"></youtube>
        </app-collapse-item>
      </app-collapse>
    </b-card-body>


    <b-form-rating
        v-model="video.rating"
        show-clear
        show-value

        stars="10"
        variant="warning"
        size="lg"
        @change="vote()"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormRating,
  BBadge,
  BMedia,
  BMediaBody,
  BMediaAside, BAvatar, BCardHeader, BRow, BCol,
} from 'bootstrap-vue'
import RatingBadge from '../RatingBadge'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import YouTube from 'vue-youtube-embed'
import moment from 'moment'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    AppCollapse,
    AppCollapseItem,
    BFormRating,
    BBadge,
    YouTube,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BCardHeader,
    BRow,
    BCol,
    RatingBadge,
  },
  props: ['video'],
  data() {
    return {
      width: 440,
    }
  },
  methods: {
    vote: function (event) {
      this.$http.post('/vote/video',
          {
            rating: this.video.rating,
            object_id: this.video.id,
          },
      ).then(res => {
        console.log('ITS OK')
      })
    },
    getWindowWidth() {
      this.width = this.$refs.video_box.clientWidth - 70;
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
  },
  computed: {
    cover() {
      //return "https://img.youtube.com/vi/"+this.video.youtube_id+"/maxresdefault.jpg";
      return 'https://i.ytimg.com/vi/' + this.video.youtube_id + '/hqdefault.jpg'
    },
  },
  mounted() {
    this.getWindowWidth();
  },

}
</script>
