<template>
  <div>
    <section id="card-content-types">
      <h1 class="mt-3">
        Клипы {{ lang }}
      </h1>
      <b-row>
        <b-col cols="12">
          <b-card-actions
              ref="cardAction"
              title="Фильтр"
              actionCollapse
          >
            <b-row>
              <b-col cols="12">
                <div>
                  <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      v-model="filter.onlyUnrated"
                  >
                    Только без оценок
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>

        <b-col v-for="video in videosList"
               md="6"
               lg="4"
               v-if="(!filter.onlyUnrated || (video.rating < 1))"
               :key="video.id"
        >
          <video-card :video="video"/>
        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
import VideoCard from './VideoCard.vue'
import { BCol, BFormCheckbox, BRow } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions'

/*eslint-disable */
export default {
  components: {
    VideoCard,
    BCardActions,
    BFormCheckbox,
    BRow,
    BCol,
  },
  data() {
    return {
      search_query: '',
      videosList: [],
      currentPage: 1,
      perPage: 1,
      rows: 140,
      filter: {
        onlyUnrated: false,
      },
    }
  },
  props: ['lang'],
  methods: {},
  created() {
    this.$http.get('/videos?lang=' + this.lang).then(res => {
      this.videosList = res.data.data
    })
  },
}
</script>
